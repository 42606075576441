import React from 'react';
import PropTypes from 'prop-types';
import './ControlGroup.css';

class ControlGroup extends React.Component {
    render() {
        return (
            <div className='control-group' style={this.props.style}>
                <div className='control-group-title'>{this.props.title}</div>
                <div className='control-group-content'>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

ControlGroup.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    style: PropTypes.object
};

export default ControlGroup;
