import React from 'react';
import PropTypes from 'prop-types';
import './Link.css';

class Link extends React.Component {
    render() {
        return (
            <a className="link" target={this.props.target} href={this.props.href}>{this.props.children}</a>
        );
    }
}

Link.propTypes = {
    target: PropTypes.string,
    href: PropTypes.string,
    children: PropTypes.node
};

export default Link;
