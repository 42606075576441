import React from 'react';
import PropTypes from 'prop-types';
import './SlideShow.css';

import arrowLeft from '../../img/arrow_back.svg';
import arrowRight from '../../img/arrow_forward.svg';


class SlideShow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: {
                i: 0,
                link: '',
                alt: ''
            }
        };

        if (props.images && props.images.length) {
            this.state.image = {
                ...props.images[0],
                i: 0,
                link: require(`../../img/${props.images[0].link}`)
            };
        }
    }

    leftArrow() {
        this.setState((state, props) => {
            const i = state.image.i === 0 ? (props.images.length - 1) : (state.image.i - 1);
            return {
                image: {
                    ...props.images[i],
                    i,
                    link: require(`../../img/${props.images[i].link}`)
                }
            };
        });
    }

    rightArrow() {
        this.setState((state, props) => {
            const i = state.image.i === (props.images.length - 1) ? 0 : (state.image.i + 1);
            return {
                image: {
                    ...props.images[i],
                    i,
                    link: require(`../../img/${props.images[i].link}`)
                }
            };
        });
    }

    render() {
        let style = {};
        if (this.props.maxHeight) {
            style.maxHeight = this.props.maxHeight;
        }

        return (
            <div className="sw-main" style={style}>
                <div className="sw-image">
                    {
                        this.state.image.link && 
                        <img className={this.props.blur ? 'sw-blurred' : ''} src={this.state.image.link} alt={this.state.image.alt}></img>
                    }
                </div>
                {
                    !!this.props.images && this.props.images.length > 1 &&
                        <div name="arrow-left" className="sw-arrow-left" onClick={this.leftArrow.bind(this)}>
                            <button><img height="32px" src={arrowLeft}/></button>
                        </div>
                }
                {
                    !!this.props.images && this.props.images.length > 1 &&
                        <div name="arrow-right" className="sw-arrow-right" onClick={this.rightArrow.bind(this)}>
                            <button><img height="32px" src={arrowRight}/></button>
                        </div>
                }
            </div>
        );
    }
}

SlideShow.propTypes = {
    images: PropTypes.array,
    maxHeight: PropTypes.string,
    blur: PropTypes.bool
};

export default SlideShow;
