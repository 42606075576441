const skills = {
    JAVASCRIPT: 'JavaScript',
    TYPESCRIPT: 'TypeScript',
    REACT: 'React',
    ANGULAR: 'Angular',
    VUE: 'Vue',
    NODE: 'Node',
    PYTHON: 'Python',
    DJANGO: 'Django',
    VSCODE_EXTENSION: 'VSCode Extension',
    EXPRESS: 'Express',
    SCSS: 'CSS/SCSS',
    GULP: 'Gulp',
    JQUERY: 'JQuery',
    PTC_THINGWORX: 'Thingworx',
    MSSQL: 'MSSQL',
    MY_SQL: 'MySQL',
    PL_SQL: 'PL/SQL',
    LARAVEL: 'Laravel',
    BLADE: 'Blade',
    JAVA: 'Java',
    C_PLUS_PLUS: 'C++',
    PHP: 'PHP',
    VB: 'VB',
    MONGO_DB: 'MongoDB',
    GE_PROFICY: 'GE Proficy',
    DOCKER: 'Docker'
};

export const skillCategory = {
    frontEnd: [{
        name: skills.JAVASCRIPT,
        children: [skills.REACT, skills.VUE2, skills.NODE, skills.JQUERY, skills.EXPRESS, skills.PTC_THINGWORX, skills.GULP]
    }, {
        name: skills.TYPESCRIPT,
        children: [skills.ANGULAR, skills.VSCODE_EXTENSION]
    }, {
        name: skills.REACT
    }, {
        name: skills.VUE
    },
    {
        name: skills.VSCODE_EXTENSION
    }, {
        name: skills.SCSS
    }, {
        name: skills.JQUERY
    }, {
        name: skills.BLADE
    }],
    backEnd: [{
        name: skills.NODE,
        children: [skills.EXPRESS]
    }, {
        name: skills.EXPRESS
    }, {
        name: skills.PYTHON,
        children: [skills.DJANGO]
    }, {
        name: skills.DJANGO
    }, {
        name: skills.JAVA
    }, {
        name: skills.PHP,
        children: [skills.LARAVEL, skills.BLADE]
    }, {
        name: skills.LARAVEL
    }, {
        name: skills.C_PLUS_PLUS
    }, {
        name: skills.VB
    }],
    database: [{
        name: skills.MSSQL,
        children: [skills.PTC_THINGWORX]
    }, {
        name: skills.MY_SQL
    }, {
        name: skills.PL_SQL
    }, {
        name: skills.MONGO_DB
    }],
    platform: [{
        name: skills.DOCKER
    }, {
        name: skills.PTC_THINGWORX
    }, {
        name: skills.GE_PROFICY
    }, {
        name: skills.GULP
    }]
};

export const experiences = [{
    name: 'PTC Software Developer',
    skills: [{
        name: skills.JAVASCRIPT
    }, {
        name: skills.SCSS
    }, {
        name: skills.JAVA
    }, {
        name: skills.MSSQL
    }, {
        name: skills.DOCKER
    }, {
        name: skills.PTC_THINGWORX
    }, {
        name: skills.NODE
    }, {
        name: skills.GULP
    }, {
        name: skills.GE_PROFICY
    }],
    smallDescription: '2019 - Current',
    description: `Currently, I am a Software Developer at PTC. 
    We develop manufacturing applications using the Thingworx platform to reduce costs and improve production on the plant floor.\\n
    We are required to create reliable and quality software as they can be critical and affect production for our customers.
    It often requires to think outside the box as the applications we create aren't simple web applications and need to interact between a lot of different end points.`,
    links: [{
        name: 'Manufacturing Applications',
        ref: 'https://www.ptc.com/en/thingworx-applications/manufacturing',
        target: '_blank'
    }]
}, {
    name: 'Hackmauricie 2020',
    skills: [{
        name: skills.REACT
    }, {
        name: skills.EXPRESS
    }, {
        name: skills.MONGO_DB
    }],
    smallDescription: 'Coup de Coeur Award / 2020',
    description: `I participated to the HackMauricie 2020 as a PTC employee. 
    The issue we had to resolve was to develop an application to help during seasonal flooding.\\n
    We won the Coup de Coeur Award for our team work, team spirit and final presentation. 
    Our app was somewhat lackluster as we mostly spent the time learning new technologies, but we gave a really good presentation at the end.`,
    links: [{
        name: 'hackmauricie.com',
        ref: 'https://hackmauricie.com/',
        target: '_blank'
    }]
}, {
    name: 'Factora Internship',
    skills: [{
        name: skills.JAVASCRIPT
    }, {
        name: skills.SCSS
    }, {
        name: skills.GULP
    }, {
        name: skills.JAVA
    }, {
        name: skills.PTC_THINGWORX
    }],
    smallDescription: 'Spring 2019',
    description: `I did an internship within Factora (now PTC) during my education. I was creating and updating widgets in JavaScript for Thingworx. 
    I've learned more about ES6+ quirks as I was paired with a front-end senior developer.\\n
    I also created test cases in Java and learned how to setup my environment.
    I was paired with a Senior Back-end developer which would review my work.`,
    links: []
}];

export const projects = [{
    name: 'zv-random-cards',
    skills: [{
        name: skills.TYPESCRIPT
    },{
        name: skills.VSCODE_EXTENSION
    }],
    smallDescription: 'Personal Project / 2022 - 2023',
    description: `Random cards is a vscode extension to randomly pick cards from a predefined deck. It was initially a project to reminds us to take a break during long coding sessions and do a certain exercise based on the card that was randomly drawn.\\n
    The original idea came from Frederik Grondin and Sébastien DeGagné and I contributed to the project.`,
    links: [{
        name: 'zv-random-cards',
        ref: 'https://marketplace.visualstudio.com/items?itemName=zveillette.zv-random-cards',
        target: '_blank'
    }],
    images: [
        {
            link: 'zv-random-cards.png',
            alt: 'VS Code view: Random cards'
        }
    ]
},{
    name: 'Z-List',
    skills: [{
        name: skills.PYTHON
    }, {
        name: skills.DJANGO
    }, {
        name: skills.ANGULAR
    }, {
        name: skills.SCSS
    }, {
        name: skills.VSCODE_EXTENSION
    }, {
        name: skills.DOCKER
    }],
    smallDescription: 'Personal Project / 2022 - 2023',
    description: `Z-List is multiple applications that interact with the same API. The project is a simple lists / tasks application. It was originaly created to learn Python, Typescript and Angular.
    \\nIt currently features a CLI application in Python, a web API using Django, a web application in Angular and a VSCode extension.`,
    links: [{
        name: 'z-list.zveillette.com',
        ref: 'https://z-list.zveillette.com/',
        target: '_blank'
    }],
    images: [{
        link: 'zlist/zlist-index.png',
        alt: 'Z-List main page'
    }, {
        link: 'zlist/zlist-webapp.png',
        alt: 'Z-List web application'
    }, {
        link: 'zlist/zlist-vscode.png',
        alt: 'Z-List VSCode'
    }, {
        link: 'zlist/zlist-cli.png',
        alt: 'Z-List command line application'
    }]
}, {
    name: 'Reviewer DashBucket',
    skills: [{
        name: skills.REACT
    }, {
        name: skills.SCSS
    }, {
        name: skills.EXPRESS
    }, {
        name: skills.GULP
    }, {
        name: skills.DOCKER
    }],
    smallDescription: 'Personal Project / 2021 - 2022',
    description: `Reviewer dashbucket is a small web application for code reviews.
    It was designed as a tool to provide an overview of how long our reviews were taking and how many pull requests were merged.\\n
    The application isn't the most useful, but provides some fun statistics that can be trended in time. All aggregated data is created with the Bitbucket API.\\n
    I initially created this application to learn React.js.`,
    links: [{
        name: 'reviewer-dashbucket.zveillette.com',
        ref: 'http://reviewer-dashbucket.zveillette.com/',
        target: '_blank'
    }],
    images: [{
        link: 'reviewer-dashbucket.png',
        alt: 'Reviewer Dashbucket dashboard'
    }]
}, {
    name: 'Z-Hub',
    skills: [{
        name: skills.VUE
    }, {
        name: skills.SCSS
    }, {
        name: skills.EXPRESS
    }, {
        name: skills.GULP
    }, {
        name: skills.MSSQL
    }],
    smallDescription: 'Personal Project / 2020 - 2023',
    description: `Z-Hub is a web application for single & multiplayer games. It was designed for small games that can be played on a phone with friends and family.\\n
    It uses a JWT based authentication and lobbies handled using websockets.
    I used this project to learn more about JS frameworks (Vue in this case) and NodeJS.\\n
    Currently in progress to be migrated to Vue3 and its composition API. It was originaly built on Vue2.`,
    links: [],
    images: [{
        alt: 'Z-Hub main page',
        link: 'zhub/zhub-index.png'
    }, {
        alt: 'Z-Hub lobby',
        link: 'zhub/zhub-lobby.png'
    }, {
        alt: 'Z-Hub ScopeCreep game',
        link: 'zhub/zhub-scopecreep.png'
    }, {
        alt: 'Z-Hub SanDanger game',
        link: 'zhub/zhub-sandanger.png'
    }]
}, {
    name: 'RegroupÉcole',
    skills: [{
        name: skills.LARAVEL
    }, {
        name: skills.BLADE
    }, {
        name: skills.SCSS
    }, {
        name: skills.JAVASCRIPT
    }, {
        name: skills.JQUERY
    }, {
        name: skills.MY_SQL
    }],
    smallDescription: 'School project / 2018 - 2019',
    description: `RegroupEcole is a web application that allows the Cegep of Trois-Rivières dietetic department to provide a group-buying solution to its students.\\n
    The department provides the products available to buy for the students. 
    Once the products reach the minimum quantity required, they can be bought in bulk and split between each orders, reducing the prices for everyone. 
    Students are then notified when they can retrieve their products. An history of all orders and signature when fullfilled is then kept.\\n
    It was done in collaboration with Jessy Walker and Charles-William Morency and we received the LANEC Solutions Web Grant of 500$.`,
    links: [{
        name: 'regroupecole.cegep3r.org',
        ref: 'https://regroupecole.cegep3r.org/',
        target: '_blank'
    }, {
        name: 'LANEC Solution Web',
        ref: 'https://fondation.cegeptr.qc.ca/bourses/lanec-solutions-web/',
        target: '_blank'
    }],
    images: [{
        alt: 'RegroupÉcole main page',
        link: 'regroupecole/regroupe-index.png'
    }, {
        alt: 'RegroupÉcole Products',
        link: 'regroupecole/regroupe-boutique.png'
    }]
}, {
    name: 'Inventory simulator',
    skills: [{
        name: skills.VB
    }, {
        name: skills.MY_SQL
    }],
    smallDescription: 'School project / 2018',
    description: `Inventory simulator is a desktop application meant for the students of the dietetic department of Cegep of Trois-Rivières.\\n
    The application mocks a real inventory and teaches the students how to keep track of all their items. 
    The teachers are able to create exercices directly inside the app for their groups and easily grade them afterward.\\n
    This project was completed in collaboration with Keven Abel and Pierre Olivier Dubois-Nadeau.
    It was selected to be used in the dietetic deparment courses and potentially as a digital inventory for technicians in the future.`,
    links: []
}];

export const educations = [{
    name: 'DEC in Computer Science',
    smallDescription: 'Diploma of College Studies in Computer Science / 2016 - 2019',
    skills: [{
        name: skills.JAVA
    }, {
        name: skills.C_PLUS_PLUS
    }, {
        name: skills.VB
    }, {
        name: skills.JAVASCRIPT
    }, {
        name: skills.SCSS
    }, {
        name: skills.PHP
    }, {
        name: skills.MY_SQL
    }, {
        name: skills.PL_SQL
    }],
    description: `Learnt the basis of programming, routing and UX design.`,
    links: [{
        name: 'https://www.cegeptr.qc.ca',
        ref: 'https://www.cegeptr.qc.ca/cours-cegep/programmes-techniques/informatique-gestion/',
        target: '_blank'
    }]
}, {
    name: 'DES',
    smallDescription: 'Diploma of Secondary Studies / 2016',
    links: [{
        name: 'https://chavigny.csscdr.gouv.qc.ca',
        ref: 'https://chavigny.csscdr.gouv.qc.ca/',
        target: '_blank'
    }]
}];