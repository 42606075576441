import React from 'react';
import './Divider.css';
import PropTypes from 'prop-types';

class Divider extends React.Component {
    render() {
        const classes = ['divider'];

        if (this.props.transition) {
            classes.push('divider-transition');
        }

        return (
            <div className={classes.join(' ')}></div>
        );
    }
}

Divider.propTypes = {
    transition: PropTypes.bool
};

export default Divider;
