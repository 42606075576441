import React from 'react';
import './Footer.css';

class Footer extends React.Component{
    render() {
        return (
            <footer className="footer">
                2016-2023 - Zachary Veillette
            </footer>
        );
    }
}

export default Footer;
