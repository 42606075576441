import React from 'react';
import './App.css';
import Home from './component/Home';
import Project from './component/Project';
import Footer from './component/layout/Footer';
import SkillBadge from './component/controls/SkillBadge';
import Section from './component/layout/Section';
import ControlGroup from './component/controls/ControlGroup';
import { skillCategory, experiences, projects, educations } from './content';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasSelectedSkills: false,
            skillCategory: { ...skillCategory },
            experiences: [...experiences],
            projects: [...projects],
            educations: [...educations] 
        };
    }

    filterOnSkills(e) {
        const updatedSkills = { ...this.state.skillCategory };
        const updateSkillSelection = (skillSelection) => {
            Object.keys(updatedSkills).forEach((category) => {
                updatedSkills[category].forEach((skill) => {
                    if (skill.name !== skillSelection.name) {
                        return;
                    }


                    if (skillSelection.isChild) {
                        skill.ghostSelected = skillSelection.selected;
                    } else {
                        skill.selected = skillSelection.selected;
                    }

                    if (skill.children) {
                        skill.children.forEach((child) => updateSkillSelection({ name: child, selected: skillSelection.selected, isChild: true }));
                    }
                });
            });
        };

        updateSkillSelection(e);

        const flattenSkills = Object.keys(updatedSkills).reduce((flat, category) => [...flat, ...updatedSkills[category]], []);
        const filterSection = (project) => {
            project.selected = false;

            if (!project.skills) {
                return project;
            }

            flattenSkills.forEach((skill) => {
                const pSkills = [];
                let pSkill = project.skills.find((pSkill) => pSkill.name === skill.name);

                if (pSkill) {
                    pSkills.push(pSkill);
                }

                if (skill.children) {
                    skill.children.forEach((child) => {
                        pSkill = project.skills.find((pSkill) => pSkill.name === child);

                        if (pSkill) {
                            pSkills.push(pSkill);
                        }
                    });
                }

                pSkills.forEach((pSkill) => {
                    if (!hasSelectedSkills){
                        pSkill.selected = false;
                        return;
                    }

                    pSkill.selected = skill.selected || skill.ghostSelected;
                    if (pSkill.selected) {
                        project.selected = true;
                    }
                });
            });

            return project;
        };

        const hasSelectedSkills = flattenSkills.some((skill) => skill.selected);
        const filteredExperiences = this.state.experiences.map(filterSection);
        const filteredProjects = this.state.projects.map(filterSection);
        const filteredEducations = this.state.educations.map(filterSection);

        this.setState({
            skillCategory: updatedSkills,
            hasSelectedSkills: hasSelectedSkills,
            experiences: filteredExperiences,
            projects: filteredProjects,
            educations: filteredEducations
        });
    }

    render() {
        const skills = Object.keys(this.state.skillCategory).reduce((map, category) => {
            map[category] = skillCategory[category].map((skill) => 
                (<SkillBadge key={skill.name} name={skill.name} onSelect={this.filterOnSkills.bind(this)}>{skill.name}</SkillBadge>));
            
            return map;
        }, {});

        const projects = this.state.projects.map((project) => 
            (<Project blur={this.state.hasSelectedSkills && !project.selected} project={project} key={project.name} minHeight={project.images ? '25rem' : null} />));

        const education = this.state.educations.map((project) => 
            (<Project blur={this.state.hasSelectedSkills && !project.selected} project={project} key={project.name} />));

        const experience = this.state.experiences.map((project) => 
            (<Project blur={this.state.hasSelectedSkills && !project.selected} project={project} key={project.name} />));

        return (
            <main className="app">
                <Home />
                <Section title='Skills'>
                    <p className="skills-help">Select one or multiple skills to filter the page</p>    
                    <ControlGroup title='Front End' style={{ maxWidth: '1000px' }}>
                        <div className="skills-ctn">{skills.frontEnd}</div>
                    </ControlGroup>
                    <ControlGroup title='Back End' style={{ maxWidth: '1000px' }}>
                        <div className="skills-ctn">{skills.backEnd}</div>
                    </ControlGroup>
                    <ControlGroup title='Database' style={{ maxWidth: '1000px' }}>
                        <div className="skills-ctn">{skills.database}</div>
                    </ControlGroup>
                    <ControlGroup title='Platform / Tool' style={{ maxWidth: '1000px' }}>
                        <div className="skills-ctn">{skills.platform}</div>
                    </ControlGroup>
                </Section>
                <Section title='Experience'>
                    {experience}
                </Section>
                <Section title='Projects'>
                    {projects}
                </Section>
                <Section title='Education'>
                    {education}
                </Section>
                <Footer />
            </main>
        );
    }
}

export default App;
