import React from 'react';
import './Section.css';
import PropTypes from 'prop-types';
import Divider from './Divider';
import LazyLoad from 'react-lazyload';

class Section extends React.Component{
    render() {
        let title = null;
        if (this.props.title){
            title = (
                <div className='section-title'>
                    <h1>{this.props.title}</h1>
                    <Divider transition />
                </div>
            );
        }

        return (
            <LazyLoad height={300}>
                <section className='section'>
                    <div className='section-content'>
                        {title}
                        {this.props.children}
                    </div>
                </section>
            </LazyLoad>
        );
    }
}

Section.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    lazyOffset: PropTypes.number
};

export default Section;
