import React from 'react';
import './Home.css';
import Link from './controls/Link';
import me from '../img/me.jpg';

class Home extends React.Component{
    render() {
        return (
            <div className="home">
                <div className="me">
                    <img width="300" height="270" src={me} alt="Zachary Veillette"></img>
                </div>
                <h1 className="title">Zachary Veillette</h1>
                <div className="subtitle">Software Developer</div>
                <div><Link target="_blank" href="https://www.linkedin.com/in/zachary-veillette-1b5a3221a">LinkedIn</Link></div>
            </div>
        );
    }
}

export default Home;
