import React from 'react';
import PropTypes from 'prop-types';
import './SkillBadge.css';

class SkillBadge extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: false
        };
    }

    select(event) {
        if (this.props.readonly){
            return;
        }

        const isSelected = !this.state.selected;

        this.setState({
            selected: isSelected
        });
        
        if (this.props.onSelect) {
            this.props.onSelect({
                event,
                name: this.props.name,
                selected: isSelected
            });
        }
    }

    render() {
        let css = 'skill-badge';

        if (!this.props.readonly) {
            css += ' skill-badge-clickable';
        }

        if (this.props.blur) {
            css += ' sb-blurred';
        }

        if (this.props.small) {
            css += ' sb-small';
        }

        if (this.state.selected || this.props.selected) {
            css += ' selected';
        }

        return (
            <div>
                <div className={css} name={this.props.name} onClick={this.select.bind(this)}>{this.props.children}</div>
            </div>
        );
    }
}

SkillBadge.propTypes = {
    name: PropTypes.string,
    children: PropTypes.node,
    readonly: PropTypes.bool,
    onSelect: PropTypes.func,
    selected: PropTypes.bool,
    blur: PropTypes.bool,
    small: PropTypes.bool
};

export default SkillBadge;
