import React from 'react';
import PropTypes from 'prop-types';
import './Project.css';
import Link from './controls/Link';
import SkillBadge from './controls/SkillBadge';
import LazyLoad from 'react-lazyload';
import SlideShow from './controls/SlideShow';

class Project extends React.Component {
    render() {
        let links = [];
        if (this.props.project.links && this.props.project.links.length) {
            links = this.props.project.links.map((link) => {
                return (<Link key={link.name} target={link.target} href={link.ref}>{link.name}</Link>);
            });
        }

        let p = [];
        if (this.props.project.description) {
            p = this.props.project.description.split('\\n').map((para, i)=> {
                return (<p key={i}>{para}</p>);
            });
        }

        let images = [];
        if (this.props.project.images) {
            images = (<SlideShow images={this.props.project.images} maxHeight="25rem" blur={this.props.blur} />);
        }

        return (
            <LazyLoad height={250} once>
                <article style={this.props.minHeight ? { minHeight: this.props.minHeight } : {}} className={this.props.blur ? 'project blurred' : 'project'}>
                    <div className={this.props.project.images ? 'project-details-sm' : 'project-details'}>
                        <h3>{this.props.project.name}</h3>
                        {!!this.props.project.smallDescription && <div className="project-small-desc">{this.props.project.smallDescription}</div>}
                        <div className="project-skills">
                            {(this.props.project.skills || []).map((skill)=> {
                            return (
                                <SkillBadge key={skill.name} small readonly selected={skill.selected} blur={this.props.blur}>{skill.name}</SkillBadge>
                            );
                        })}
                        </div>
                        <div className="project-desc">
                            {p}
                            <div className="actions">
                                {links}
                            </div>
                        </div>
                    </div>
                    {
                        !!this.props.project.images && 
                        <div className="project-image">
                            {images}
                        </div>
                    }
                </article>
            </LazyLoad>
        );
    }
}

Project.propTypes = {
    project: PropTypes.object,
    minHeight: PropTypes.string,
    blur: PropTypes.bool
};

export default Project;
